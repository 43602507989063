<template>
  <div>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
      @after-leave="clearSelected"
    >
      <ContentPanel
        v-if="selected"
        title="Nowa strefa w terminarzu"
        icon="calendar-plus"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            :loading="loading"
            size="is-small"
            v-if="modalOccupied"
            :disabled="areaErrors"
            @click="sendWorktimes(0)"
            class="mr-1"
            icon-left="calendar-import"
          >Dodaj strefę obok istniejącej</b-button>
          <b-button
            type="is-warning"
            :loading="loading"
            size="is-small"
            v-if="modalOccupied"
            :disabled="areaErrors"
            class="mr-1"
            @click="sendWorktimes(1)"
            icon-left="calendar-import"
          >
            <b>Nadpisz strefę</b>
          </b-button>
          <!-- <b-button
            type="is-warning"
            :loading="loading"
            size="is-small"
            v-if="modalOccupied"
            :disabled="areaErrors"
            @click="sendWorktimes( 3)"
            icon-left="calendar-import"
          >Wypełnij luki</b-button>-->
          <!-- <b-button
              type="is-success"
              :loading="loading"
              v-if="modalOccupied"
              @click="sendWorktimes(false)"
              icon-left="calendar-weekend"
              :disabled="modalAmount === 0 && modalBackupAmount === 0"
          >Dodaj do terminarza (Wypełnij)</b-button>-->
          <b-button
            type="is-primary"
            size="is-small"
            v-else
            :loading="loading"
            @click="sendWorktimes( 0)"
            icon-left="calendar-alert"
            :disabled="areaErrors"
          >Dodaj do terminarza</b-button>
          <b-button
            icon-left="close"
            size="is-small"
            class="ml-1"
            @click="isModalActive = false"
          >Anuluj</b-button>
        </template>

        <div class="modal-card" style="width:100% !important;min-height: 70vh;">
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-6">
                <table class="table w-100 is-bordered">
                  <tr v-if="filters.searchType === 0">
                    <th colspan="2">
                      <p class="subtitle">{{ selected.lastName }} {{ selected.firstName }}</p>
                    </th>
                  </tr>
                  <tr v-if="filters.searchType > 0">
                    <th colspan="2">
                      <p
                        class="subtitle"
                        v-if="selected && selected.originalName"
                      >{{ selected.originalName }}</p>
                      <p class="subtitle" v-else-if="selected && selected.name">{{ selected.name }}</p>
                    </th>
                  </tr>
                  <tr v-if="filters.searchType > 0">
                    <th colspan="2">
                      <p
                        class="has-text-weight-normal mt-0"
                        v-if="selected && selected.subUnitName"
                      >{{ selected.subUnitName }}</p>
                    </th>
                  </tr>
                  <tr v-if="filters.searchType > 0">
                    <th colspan="2">
                      <p
                        class="has-text-weight-normal mt-0"
                        v-if="selected && selected.facilityName"
                      >{{ selected.facilityName }}</p>
                    </th>
                  </tr>
                  <tr v-if="filters.searchType == 4">
                    <td>
                      <b-icon icon="folder-open-outline" class="mr-2 mb-1" size="is-small"></b-icon>Typ
                    </td>
                    <td>
                      <p class="has-text-primary" v-if="selected">{{ getItem(selected.type) }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 6.5rem;">
                      <b-icon icon="clock-outline" class="mr-2 mb-1" size="is-small"></b-icon>Czas
                    </td>
                    <td>
                      <span v-if="!isWholeDay">
                        <b-tag>{{ mTime(modalStart) }} - {{ mTime(modalStop) }}</b-tag>
                      </span>
                      <span v-else>
                        <b-tag>06:00 - 20:00</b-tag>
                      </span>
                      <span v-if="specialArea" class="is-pulled-right mb-0">
                        <b-switch
                          v-model="isWholeDay"
                          size="is-small"
                          class="mb-0"
                          :rounded="false"
                        >Cały dzień</b-switch>
                      </span>
                    </td>
                  </tr>
                  <tr v-show="modalOccupied">
                    <td>
                      <b-icon icon="alert" class="mr-2 mb-1" size="is-small"></b-icon>Uwagi
                    </td>
                    <td>
                      <p
                        class="has-text-danger has-text-weight-semibold"
                      >UWAGA! Konflikt z inną strefą!</p>
                      <p>Możesz nadpisać istniejącą strefę, co spowoduje usunięcie fragmentów starszej strefy będących pod strefą nową.</p>
                      <!-- <p>Możesz także wypełnić luki, co spowoduje pozostawienie starej strefy w obecnym formacie, a nowa strefa zostanie wstawiona tylko w wolne miejsca.</p> -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon icon="calendar" class="mr-2 mb-1" size="is-small"></b-icon>Daty
                    </td>
                    <td>
                      <b-tag
                        v-for="item in selectedDays"
                        :key="item.index"
                        class="mr-1 mb-1"
                      >{{ item }}</b-tag>
                    </td>
                  </tr>
                </table>

                <b-field label="Dodatkowy opis strefy" label-position="on-border" class="mt-5">
                  <b-input type="textarea" maxlength="400" v-model="worktimeArea.info"></b-input>
                </b-field>
                <b-field expanded label="Dodatkowe wyróżnienie" label-position="on-border">
                  <b-select expanded v-model="worktimeArea.style">
                    <option :value="0">Brak</option>
                    <option :value="1">Paskowane tło boczne</option>
                    <option :value="2">Ciemne tło boczne</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <div v-if="filters.searchType === 0">
                  <b-field style="text-align: right"></b-field>
                  <b-field label="Typ strefy" label-position="on-border">
                    <b-select
                      v-if="specialArea"
                      expanded
                      v-model="worktimeArea.area"
                      placeholder="Wybierz"
                    >
                      <!-- <option v-for="item in docSpecs" :key="item" :value="item">
                    {{ getWorkAreaName(item) }} ({{ item }})
                      </option>-->
                      <option v-if="specialArea && filters.searchType == 0" :value="99">Urlop</option>
                      <option v-if="specialArea && filters.searchType == 0" :value="97">Delegacja</option>
                    </b-select>
                    <b-select v-else expanded v-model="worktimeArea.area">
                      <option
                        v-for="item in docSpecs"
                        :key="item"
                        :value="item"
                      >{{ getWorkAreaName(item) }} ({{ item }})</option>
                    </b-select>
                    <b-switch
                      v-model="specialArea"
                      type="is-primary"
                      :rounded="false"
                      size="is-small"
                      class="ml-2"
                    >Strefy specjalne</b-switch>
                  </b-field>
                  <b-field
                    v-if="
                  specialArea &&
                  (worktimeArea.area == 99 || worktimeArea.area == 97)
                "
                    label-position="on-border"
                    label="Zastępca"
                    class="wide"
                  >
                    <b-autocomplete
                      :data="employeesFiltered"
                      v-model="additionalNameDeputy"
                      clearable
                      field="name"
                      open-on-focus
                      placeholder="Wybierz zastępcę"
                    ></b-autocomplete>
                  </b-field>
                  <b-field v-if="!specialArea" label="Poradnia" label-position="on-border">
                    <b-select expanded v-model="worktimeArea.clinic">
                      <option
                        v-for="item in selected.clinics"
                        :key="item"
                        :value="item"
                      >{{ mGetClinic(item) }}</option>
                    </b-select>
                  </b-field>
                  <b-field v-if="!specialArea" label="Specjalizacja" label-position="on-border">
                    <b-select expanded v-model="worktimeArea.spec">
                      <option :value="-1">Brak</option>
                      <option
                        v-for="item in selected.specializations"
                        :key="item"
                        :value="item"
                      >{{ mGetSpecialization(item) }}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- OFFICE -->
                <div v-if="filters.searchType === 4">
                  <b-field grouped>
                    <b-field expanded label="Typ strefy" label-position="on-border">
                      <b-select expanded v-model="worktimeArea.area">
                        <option
                          v-for="item in docSpecs"
                          :key="item"
                          :value="item"
                        >{{ getWorkAreaName(item) }}</option>
                        <option
                          v-if="specialArea && filters.searchType != 0"
                          :value="98"
                        >Strefa serwisowa</option>
                      </b-select>
                    </b-field>
                    <b-field>
                      <b-switch
                        v-model="specialArea"
                        type="is-primary"
                        size="is-small"
                        :rounded="false"
                        class="ml-2 mt-1"
                        style="line-height: .9rem;"
                      >
                        Strefy
                        <br />specjalne
                      </b-switch>
                    </b-field>
                  </b-field>

                  <b-field label-position="on-border" label="Pracownik" class="wide">
                    <b-autocomplete
                      :data="employeesFiltered"
                      v-model="additionalName"
                      clearable
                      field="name"
                      open-on-focus
                      @select="(option) => workerToOffice(option)"
                      :style="[ selected && selected.type === 200001 ? { border: '1px solid #f14668' } : {}, ]"
                    >
                      <template slot-scope="props">
                        <p
                          class="title is-7 mb-0"
                          :class="{'has-text-grey':props.option.isOtherDomain}"
                          style="line-height: .75rem;"
                        >
                          {{ props.option.lastName }}
                          {{ props.option.firstName }}
                        </p>
                        <p
                          v-if="props.option.isOtherDomain"
                          class="has-text-danger is-size-7"
                        >Uwaga! Inna domena/obszar</p>
                      </template>
                    </b-autocomplete>
                  </b-field>

                  <b-field
                    v-if="filters.searchType === 4"
                    label-position="on-border"
                    :label="
                  showAllRooms
                    ? `Pomieszczenie (${rooms.length})`
                    : `Pomieszczenie w placówce (${rooms.length})`
                "
                    class="wide mb-0"
                    expanded
                  >
                    <b-autocomplete
                      expanded
                      :data="rooms"
                      v-model="roomName"
                      clearable
                      field="name"
                      type="is-primary"
                      open-on-focus
                      @select="(option) => roomChanged(option)"
                    ></b-autocomplete>
                  </b-field>
                  <b-field v-if="filters.searchType === 4">
                    <b-switch
                      v-model="showAllRooms"
                      :rounded="false"
                      size="is-small"
                    >Pomieszczenie w innej placówce</b-switch>
                  </b-field>
                  <b-field
                    v-if="filters.searchType === 4"
                    label-position="inside"
                    label="Urządzenie"
                    class="wide"
                    :type="{ 'is-danger': configClinicSelected &&configClinicSelected.isMedicalDeviceRequired && includedDevices.length == 0 }"
                    expanded
                  >
                    <b-taginput
                      v-model="includedDevices"
                      :data="subjectsDevicesOffice"
                      autocomplete
                      :allow-new="false"
                      open-on-focus
                      type="is-primary"
                      field="name"
                      :placeholder="devicePlaceholder"
                    >
                      <template v-slot="props">
                        <p style="word-wrap: break-word; white-space: pre-wrap">
                          <span>{{ props.option.name }}</span>
                        </p>
                      </template>
                    </b-taginput>
                  </b-field>
                </div>
                <div v-if="filters.searchType === 6">
                  <b-field expanded label="Typ strefy" label-position="inside">
                    <b-select expanded v-model="worktimeArea.area">
                      <option :value="-1">{{ selected.name }}</option>
                      <option
                        v-if="specialArea && filters.searchType != 0"
                        :value="98"
                      >Strefa serwisowa</option>
                    </b-select>
                    <b-switch
                      v-model="specialArea"
                      type="is-primary"
                      size="is-small"
                      :rounded="false"
                      class="ml-2"
                    >Strefy specjalne</b-switch>
                  </b-field>
                </div>
                <div v-if="filters.searchType === 20">
                  <b-field expanded label="Typ strefy" label-position="inside">
                    <b-select expanded v-model="worktimeArea.area">
                      <option
                        v-for="item in vehicleAreas"
                        :key="item.id"
                        :value="item.id"
                      >{{ item.name }}</option>
                    </b-select>
                  </b-field>
                  <b-field label-position="on-border" label="Pracownik" class="wide">
                    <b-autocomplete
                      :data="employeesFiltered"
                      v-model="additionalName"
                      clearable
                      field="name"
                      open-on-focus
                      @select="(option) => workerToOffice(option)"
                    ></b-autocomplete>
                  </b-field>
                </div>
                <b-field class="mt-3 mb-0">
                  <b-switch
                    v-if="allowCito"
                    size="is-small"
                    :rounded="false"
                    type="is-danger"
                    v-model="worktimeArea.isCito"
                  >Strefa wizyt CITO</b-switch>
                </b-field>
                <b-message
                  class="mt-3"
                  has-icon
                  v-if="worktimeArea.isCito"
                  type="is-info"
                >Strefa zostanie podświetlona na czerwono w terminarzu.</b-message>
                <b-field class="mb-0">
                  <b-switch
                    size="is-small"
                    :rounded="false"
                    v-model="worktimeArea.isEr"
                  >Strefa E-Rejestracji</b-switch>
                </b-field>
                <b-field class="mb-0">
                  <b-switch
                    size="is-small"
                    :rounded="false"
                    v-model="isVisibleForPatients"
                  >Strefa widoczna na portalu pacjenta</b-switch>
                </b-field>
                <b-field>
                  <b-switch
                    :rounded="false"
                    size="is-small"
                    v-model="cyclicalWorktime"
                  >Strefa cykliczna</b-switch>
                </b-field>
                <div v-if="cyclicalWorktime" class="pt-3">
                  <b-field class="mb-5">
                    <b-radio v-model="cyclicalType" :native-value="0">Podanie liczby stref</b-radio>
                    <b-radio
                      v-model="cyclicalType"
                      :native-value="1"
                      class="ml-3"
                    >Podanie daty końcowej</b-radio>
                  </b-field>
                  <b-field grouped>
                    <b-field
                      v-if="cyclicalWorktime && cyclicalType == 0"
                      label="Liczba stref"
                      label-position="on-border"
                      style="width: 35%"
                    >
                      <b-numberinput v-model="numberOfWortimes" min="1" controls-position="compact"></b-numberinput>
                    </b-field>

                    <b-field
                      v-if="cyclicalWorktime && cyclicalType == 1"
                      label="Data końcowa"
                      label-position="on-border"
                    >
                      <b-datepicker
                        position="is-top-right"
                        v-model="worktimePeriodEndDate"
                        :max-date="endDateMax"
                      ></b-datepicker>
                    </b-field>
                    <b-field
                      v-if="cyclicalWorktime"
                      label="Okres"
                      label-position="on-border"
                      style="width: 35%"
                    >
                      <b-numberinput
                        v-model="worktimePeriodNumber"
                        controls-position="compact"
                        min="1"
                      ></b-numberinput>
                    </b-field>
                    <b-field expanded>
                      <b-select v-model="worktimePeriodUnit" expanded>
                        <option :value="0">dzień</option>
                        <option :value="1">tydzień</option>
                        <option :value="2">miesiąc</option>
                      </b-select>
                    </b-field>
                  </b-field>
                  <b-field v-if="cyclicalWorktime" class="mt-3">
                    <b-checkbox v-model="excludeWeekends" type="is-orange">Pomiń weekendy</b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <!------------------------------------------------------------------------------------->
    <b-modal
      :active.sync="isEditModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
      style="overflow: visible;"
    >
      <ContentPanel
        v-if="selected && selectedWorktimeArea"
        icon="calendar-edit"
        :paddingless="true"
        :marginless="true"
        :title="`Edytowanie strefy #${selectedWorktimeArea.id}`"
      >
        <template slot="addon">
          <b-button
            :disabled="
              configClinicSelected &&
              configClinicSelected.isMedicalDeviceRequired &&
              includedDevices.length == 0
            "
            :loading="loading"
            size="is-small"
            @click="modifyWorktime(selectedWorktimeArea)"
            type="is-secondary"
            class="mr-1"
            icon-left="check"
          >Zapisz zmiany</b-button>
          <b-button size="is-small" icon-left="close" @click="isEditModalActive = false">Anuluj</b-button>
        </template>

        <div class="modal-card" style="width:100% !important;overflow: visible;">
          <section class="modal-card-body" style="width:100% !important;overflow: visible;">
            <div>
              <div class="mb-3">
                <b-notification
                  v-if="modalOccupied"
                  type="is-danger"
                  class="mb-3"
                >UWAGA! Konflikt z inną strefą! Nowa strefa zostanie utworzona równolegle z już istniejącą.</b-notification>

                <b-field label="Zakres czasowy" label-position="on-border">
                  <b-datetimepicker
                    :timepicker="{ 'increment-minutes': 5 }"
                    v-model="selectedDates[0]"
                  ></b-datetimepicker>
                  <b-datetimepicker
                    :timepicker="{ 'increment-minutes': 5 }"
                    v-model="selectedDates[1]"
                  ></b-datetimepicker>
                </b-field>
              </div>
              <!-- OFFICE -->
              <div v-if="filters.searchType === 4">
                <table class="table is-bordered mb-3 w-100">
                  <tr>
                    <th>Gabinet</th>
                    <th>{{ selected.name }}</th>
                  </tr>
                  <tr>
                    <td>Typ</td>
                    <td>{{ getItem(selected.type) }}</td>
                  </tr>
                </table>
                <b-field grouped expanded class="pb-3">
                  <b-field expanded label="Typ strefy" label-position="inside">
                    <b-select expanded v-model="selectedWorktimeArea.area">
                      <option
                        v-for="item in docSpecs"
                        :key="item"
                        :value="item"
                      >{{ getWorkAreaName(item) }}</option>
                      <option
                        v-if="specialArea && filters.searchType != 0"
                        :value="98"
                      >Strefa serwisowa</option>
                    </b-select>
                  </b-field>
                  <b-switch
                    v-model="specialArea"
                    type="is-primary"
                    size="is-small"
                    :rounded="false"
                    class="ml-2"
                  >Strefy specjalne</b-switch>
                </b-field>

                <b-field label-position="on-border" label="Pracownik" class="wide">
                  <b-autocomplete
                    :data="employeesFiltered"
                    v-model="additionalName"
                    clearable
                    field="name"
                    open-on-focus
                    @select="(option) => workerToOffice(option, true)"
                  ></b-autocomplete>
                </b-field>

                <b-field
                  v-if="filters.searchType === 4"
                  label-position="on-border"
                  :label="
                  showAllRooms
                    ? `Pomieszczenie (${rooms.length})`
                    : `Pomieszczenie w placówce (${rooms.length})`
                "
                  class="wide mb-1"
                  expanded
                >
                  <b-autocomplete
                    expanded
                    :data="rooms"
                    v-model="roomName"
                    clearable
                    field="name"
                    type="is-primary"
                    open-on-focus
                    @select="(option) => roomChanged(option)"
                  ></b-autocomplete>
                </b-field>
                <b-field v-if="filters.searchType === 4" class="mb-4">
                  <b-switch v-model="showAllRooms" size="is-small" :rounded="false">Inne placówki</b-switch>
                </b-field>
                <b-field
                  v-if="filters.searchType === 4"
                  label-position="on-border"
                  label="Urządzenia"
                  class="wide"
                  expanded
                >
                  <b-taginput
                    v-model="includedDevices"
                    :data="subjectsDevicesOffice"
                    autocomplete
                    :allow-new="false"
                    open-on-focus
                    type="is-primary"
                    field="name"
                    :placeholder="devicePlaceholder"
                    clos
                    :style="[
                    configClinicSelected &&
                    configClinicSelected.isMedicalDeviceRequired &&
                    includedDevices.length == 0
                      ? { border: '1px solid #f14668' }
                      : {},
                  ]"
                  >
                    <template v-slot="props">
                      <p style="word-wrap: break-word; white-space: pre-wrap">
                        <span>{{ props.option.name }}</span>
                      </p>
                    </template>
                  </b-taginput>
                </b-field>
              </div>

              <div v-if="filters.searchType === 0">
                <p class="subtitle mt-4">{{ selected.lastName }} {{ selected.firstName }}</p>
                <b-field style="text-align: right"></b-field>
                <b-field label="Typ strefy" label-position="on-border">
                  <b-select
                    v-if="specialArea"
                    expanded
                    v-model="selectedWorktimeArea.area"
                    placeholder="Wybierz"
                  >
                    <!-- <option v-for="item in docSpecs" :key="item" :value="item">
                    {{ getWorkAreaName(item) }} ({{ item }})
                    </option>-->
                    <option v-if="specialArea && filters.searchType == 0" :value="99">Urlop</option>
                    <option v-if="specialArea && filters.searchType == 0" :value="97">Delegacja</option>
                  </b-select>
                  <b-select v-else expanded v-model="selectedWorktimeArea.area">
                    <option
                      v-for="item in docSpecs"
                      :key="item"
                      :value="item"
                    >{{ getWorkAreaName(item) }} ({{ item }})</option>
                  </b-select>
                  <b-switch
                    v-model="specialArea"
                    type="is-primary"
                    size="is-small"
                    :rounded="false"
                    class="ml-2"
                  >Strefy specjalne</b-switch>
                </b-field>
                <b-field v-if="!specialArea" label="Poradnia" label-position="on-border">
                  <b-select expanded v-model="selectedWorktimeArea.clinic">
                    <option
                      v-for="item in selected.clinics"
                      :key="item"
                      :value="item"
                    >{{ mGetClinic(item) }}</option>
                  </b-select>
                </b-field>
                <b-field v-if="!specialArea" label="Specjalizacja" label-position="on-border">
                  <b-select expanded v-model="selectedWorktimeArea.spec">
                    <option :value="-1">Brak</option>
                    <option
                      v-for="item in selected.specializations"
                      :key="item"
                      :value="item"
                    >{{ mGetSpecialization(item) }}</option>
                  </b-select>
                </b-field>
              </div>

              <div v-if="filters.searchType === 6">
                <p class="subtitle mt-4 has-text-weight-medium" v-if="selected">{{ selected.name }}</p>
                <b-field expanded label="Typ strefy" label-position="inside">
                  <b-select expanded v-model="selectedWorktimeArea.area">
                    <option :value="-1">{{ selected.name }}</option>
                    <option
                      v-if="specialArea && filters.searchType != 0"
                      :value="98"
                    >Strefa serwisowa</option>
                  </b-select>
                  <b-switch
                    v-model="specialArea"
                    type="is-primary"
                    size="is-small"
                    :rounded="false"
                    class="ml-2"
                  >Strefy specjalne</b-switch>
                </b-field>
              </div>
              <div v-if="filters.searchType === 20" class="mb-3">
                <p class="subtitle" v-if="selected">{{ selected.name }}</p>
                <b-field expanded label="Typ strefy" label-position="inside">
                  <b-select expanded v-model="selectedWorktimeArea.area">
                    <option
                      v-for="item in vehicleAreas"
                      :key="item.id"
                      :value="item.id"
                    >{{ item.name }}</option>
                  </b-select>
                </b-field>
              </div>

              <b-field class="pt-3">
                <b-switch
                  v-if="allowCito"
                  v-model="selectedWorktimeArea.isCito"
                  size="is-small"
                  :rounded="false"
                  type="is-danger"
                >Strefa CITO</b-switch>
              </b-field>

              <b-field label="Dodatkowy opis strefy" class="mt-3 mb-3" label-position="on-border">
                <b-input maxlength="400" v-model="selectedWorktimeArea.info"></b-input>
              </b-field>
              <b-field expanded label="Dodatkowe wyróżnienie" label-position="on-border">
                <b-select expanded v-model="selectedWorktimeArea.style">
                  <option :value="0">Brak</option>
                  <option :value="1">Paskowane tło boczne</option>
                  <option :value="2">Ciemne tło boczne</option>
                </b-select>
              </b-field>
              <b-field>
                <b-switch v-model="selectedWorktimeArea.isEr" :rounded="false">Strefa E-rejestracji</b-switch>
              </b-field>

              <!-- <b-field
                v-show="selectedWorktimeArea.isEr"
                label-position="on-border"
                label="ID harmonogramu E-rejestracji"
              >
                <b-input
                  v-model="selectedWorktimeArea.erId"
                  placeholder="Wymagane do przesyłania informacji do NFZ"
                ></b-input>
              </b-field> -->
            </div>
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <b-modal
      :active.sync="isEditMultiModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="overflow: visible; width: auto">
        <!-- <header class="modal-card-head has-background-kin">
          <b-icon icon="pencil" type="is-primary" class="mr-2"></b-icon>
          <p class="modal-card-title has-text-primary">Edycja stref</p>
          <button
            type="button"
            class="delete"
            @click="isEditMultiModalActive = false"
          />
        </header>-->
        <section class="modal-card-body" style="overflow: visible">
          <b-field label="Przesuń strefy o ... minut" label-position="on-border">
            <b-numberinput
              v-model="minutes"
              controls-position="compact"
              style="width: 15rem"
              expanded
            ></b-numberinput>
            <b-button
              @click="moveDayWorktimes"
              :disabled="minutes == 0"
              class="ml-2 mr-2"
              type="is-primary"
              icon-left="check"
              :loading="loading"
            >Zapisz przesunięcie</b-button>
            <b-button @click="isEditMultiModalActive = false" icon-left="close">Anuluj</b-button>
          </b-field>
        </section>
        <!-- <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button @click="isEditMultiModalActive = false"> Anuluj </b-button>
          <b-button @click="todo" disabled type="is-primary"> Zapisz </b-button>
        </footer>-->
      </div>
    </b-modal>

    <!-- <table>
      <tr>
        <td>
          <pre>{{worktimeArea}}</pre>
        </td>
        <td>
          <pre>{{filters}}</pre>
        </td>
      </tr>
    </table>-->
    <!-- <pre>cyclicalWorktime: {{ cyclicalWorktime }}</pre>
    <pre>isVisibleForPatients: {{ isVisibleForPatients }}</pre>-->

    <b-modal
      v-model="isCyclicalWorktimesActive"
      has-modal-card
      aria-role="dialog"
      aria-modal
      class="p-5"
      trap-focus
      :destroy-on-hide="false"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin p-3">
          <p class="modal-card-title has-text-primelight pl-1 pb-1">
            <b-icon icon="alert-circle" class="mr-3"></b-icon>Wykryto konflikty
            przy zapisie stref cyklicznych
          </p>
          <button type="button" class="delete" @click="isCyclicalWorktimesActive = false" />
        </header>
        <section
          v-if="cyclicalWorktimeSaveResponse"
          class="modal-card-body pt-2 pr-4 pl-4 pb-2"
          style="overflow-y: auto"
        >
          <p class="mt-3 mb-4">Wykryto konflikty dla stref:</p>
          <ul class="ml-4">
            <li
              v-for="(
                conflict, conflict_index
              ) in cyclicalWorktimeSaveResponse.conflicts"
              :key="conflict_index"
            >
              {{ conflict_index + 1 }}. {{ getFormattedDate(conflict.start) }} -
              {{ getFormattedDate(conflict.stop) }}
            </li>
          </ul>
          <hr />
        </section>
        <footer class="modal-card-foot">
          <b-button
            @click="saveCyclicalWorktimesWithConflicts(2)"
            icon-left="calendar-plus"
            type="is-primary"
            :loading="loading"
          >Dodaj z pominięciem konfliktów</b-button>
          <b-button
            @click="saveCyclicalWorktimesWithConflicts(1)"
            icon-left="calendar-plus"
            type="is-danger"
            :loading="loading"
          >Nadpisz strefy</b-button>
          <b-button @click="isEditMultiModalActive = false" icon-left="close">Anuluj</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
// import ContentPanel from '@/components/tools/ContentPanel'
import CommonMixins from '@/mixins/commons'
import ClinicsMixins from '@/mixins/clinics'
import PersonnelMixins from '@/mixins/personnel'
import InvMixins from '@/mixins/inventory'
import AppoMixins from '@/mixins/appointments'
import MobiMixins from '@/mixins/mobi'
import DateHelpers from '@/mixins/date_helpers'
import { Action } from '@/store/config_actions'
//import { Action } from '../../../store/config_actions'
// import WorkFill from './WorktimeFiller'

export default {
  name: 'Schedule2Editor',
  mixins: [CommonMixins, DateHelpers, AppoMixins, ClinicsMixins, PersonnelMixins, InvMixins, MobiMixins],
  components: {},

  data: function () {
    return {
      slots: [],
      dates: [],
      selected: null,
      dragging: false,
      markOne: null,
      markTwo: null,
      modalStart: null,
      modalStop: null,
      modalAmount: 0,
      modalBackupAmount: 0,
      isModalActive: false,
      modalStamp: null,
      employee: null,
      loading: false,
      subjectName: '',
      scheduleData: { days: [] },
      tmp: null,
      styles: [],
      contracts: [],
      contract: null,
      clearTrigger: 0,
      popX: null,
      popY: null,
      areaType: 0,
      modalOccupied: false,
      medicalDevices: [],
      vehicles: [],
      devicesInOffice: [],
      reserveDevices: false,
      allRooms: [],
      roomName: '',
      showAllRooms: false,
      includedDevices: [],
      officeInProgress: false,
      additionalName: '',

      filters: {
        "searchType": 4,
        "dates": [
        ],
        "employee": -1,
        "device": -1,
        "clinic": -1,
        "spec": -1,
        "area": -1,
        "office": -1,
        "room": -1,
        "vehicle": -1
      },

      worktimeArea: {
        "start": null,
        "stop": null,
        "subjectId": -1,
        "employee": -1,
        "employees": [],
        "subject": 0,
        "style": 0,
        "spec": -1,
        "area": -1,
        "info": '',
        "clinic": -1,
        "office": -1,
        "officeId": null,
        "officeRoomId": null,
        "isEr": false,
        "vehicleId": null,
        "created": this.mNow(),
        "createdBy": -1,
        "modified": this.mNow(),
        "modifiedBy": -1,
        isCito: false
      },

      office: null,
      selectedDays: [],

      specialArea: false,
      calendarRangeMode: false,
      isEditModalActive: false,
      isEditMultiModalActive: false,
      selectedWorktimeArea: null,
      selectedWorktimeAreaApps: [],
      selectedAreas: [],
      selectedDates: [],
      devicePlaceholder: "",
      additionalNameDeputy: "",
      isWholeDay: false,
      showWeekends: 0,
      minutes: 0,
      areaDescription: '',



      //



      allowCito: false,

      cyclicalWorktime: false,
      isEregistration: false,
      cyclicalType: 0,
      numberOfWortimes: 1,
      worktimePeriodNumber: 1,
      worktimePeriodUnit: 0,
      worktimePeriodEndDate: new Date(),
      endDateMax: new Date(moment().add(5, 'year')),

      isVisibleForPatients: false,

      sendedWorktimes: null,

      isCyclicalWorktimesActive: false,
      cyclicalWorktimeSaveResponse: null,

      excludeWeekends: false,
      showAllOptions: true,

      testKey: 0
    }
  },

  props: {
    mobi: { type: Boolean, required: false },
    existing: { type: Object, required: false },
    day: { type: Object, required: false },
    payload: { type: Object, required: false },
    selectedDayIds: { type: Array, required: false },
    selectedSlots: { type: Array, required: false },
    editTrigger: { type: Number, required: false },
    multichangeTrigger: { type: Number, required: false },
    creatorTrigger: { type: Number, required: false }
  },

  mounted() {
    if (this.mobi) {
      this.showAllOptions = false
      this.filters.searchType = 20
    }


    this.downloadMedicalDevices()
    this.downloaVehicles()
    this.getOffroomz()
  },

  watch: {

    "worktimeArea.area"(val) {

      if (val) {
        var ares = this.configAreas.find(x => x.id == val)

        if (ares) {
          this.allowCito = ares.allowCito
        }
      }

    },

    creatorTrigger() {

      if (this.payload && this.payload.days && this.selectedDayIds && this.selectedSlots && this.selectedSlots.length > 0) {
        let candidates = []
        let candidateDays = []

        this.selectedDayIds.forEach(id => {
          let dayToBeAdded = this.payload.days.find(d => d.id == id)

          if (dayToBeAdded) {
            candidates.push(moment(dayToBeAdded.date).format('YYYY-MM-DD'))
            candidateDays.push(dayToBeAdded)
          }
        })

        this.selectedDays = candidates

        if (candidateDays.length > 0 && this.selectedSlots.length > 0) {

          this.selectedSlots.sort(this.sortByMoment)
          let first = this.selectedSlots[0]
          let last = this.selectedSlots[this.selectedSlots.length - 1]

          this.modalStart = moment(first.start)
          this.modalStop = moment(last.end)

          if (candidateDays[0].office) {
            this.targetChanged(candidateDays[0].office)
          }

          let conflictPresent = false

          candidateDays.forEach(day => {
            if (day.worktimes) {
              this.selectedSlots.forEach(slot => {
                let conflict = day.worktimes.find(s => this.isIncluded(s, slot.start))

                if (conflict) {
                  conflictPresent = true
                }
              });
            }
          })

          this.modalOccupied = conflictPresent
          this.isModalActive = true
        }
      }
    },

    multichangeTrigger() {

      if (this.day && this.day.worktimes && this.day.worktimes.length > 0) {
        this.selectedAreas = this.day;
        this.isEditMultiModalActive = true;
      }
    },

    editTrigger() {

      if (this.existing && this.existing.worktimeArea) {

        if (this.existing.worktimeArea.officeId != null) {
          this.selected = this.offices.find(x => x.id == this.existing.worktimeArea.officeId)
          this.filters.searchType = 4
        }

        var ares = this.configAreas.find(x => x.id == this.existing.worktimeArea.area)
        if (ares) {
          this.allowCito = ares.allowCito
        }

        this.openEditModal(this.existing.worktimeArea)
      }
    },


    isModalActive() {
      this.worktimeArea.info = null
      this.worktimeArea.isCito = false
      this.worktimeArea.isEr = false
      this.includedDevices = [];
      if (this.selected && this.isModalActive == true && this.isEditModalActive == false && this.filters.searchType == 4) {
        if (this.subjectsDevicesOffice != null) {
          let found = this.subjectsDevicesOffice.find(d => d.officeId == this.selected.id)

          if (found != null && !this.includedDevices.some(incD => incD.id == found.id)) {
            this.includedDevices.push(found)
          }

        }
      }
    },


    'dates.length': function () {
      this.updateDates()
    },

    dates() {
      if (this.calendarRangeMode) {
        this.updateDates()
      }
    },

    calendarRangeMode() {
      this.clearDates()
    },

    docSpecs(val) {
      if (val && val.length > 0) {
        this.worktimeArea.area = val[0]
      }
    },

    specialArea() {
      if (this.specialArea) {
        if (this.filters.searchType == 0) {
          this.worktimeArea.area = 99
        } else {
          this.worktimeArea.area = 98
        }
      }
    },

    showWeekends() {
      this.scheduleData.days.forEach(element => {
        let weekDay = new Date(element.date).getDay()
        if (weekDay == 0 || weekDay == 6) {
          element.checkboxSelected = !element.checkboxSelected
          this.fillSlots(this.scheduleData.days.length, this.scheduleData.days)
        }
      })
    }
  },

  computed: {

    areaErrors() {
      return (this.configClinicSelected && this.configClinicSelected.isMedicalDeviceRequired && (!this.includedDevices || this.includedDevices.length == 0)) ||
        (this.modalAmount === 0 && this.modalBackupAmount === 0) ||
        (this.filters.searchType === 4 && this.selected && this.selected.type === 200001 && this.worktimeArea.employee === -1)
    },

    subunits() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    offices() {
      return this.$store.state.offices.offices;
    },

    docSpecs() {
      let areas = []

      if (this.filters.searchType === 0 && this.selected) {
        this.selected.roles.forEach(p => {
          let pos = this.mGetRoleObject(p)
          if (pos) {
            pos.areas.forEach(a => {
              if (!areas.includes(a)) {
                areas.push(a)
              }
            })
          }
        })
      }
      else if (this.filters.searchType === 4 && this.selected) {
        let officeAreas = this.getOfficeAreas(this.selected)

        officeAreas.forEach(element => {
          areas.push(element)
        })
      }
      else if (this.filters.searchType === 6 && this.selected) {
        areas.push(this.selected.id)
      }

      return areas
    },

    facilities() { return this.$store.state.clinic.clinics },


    configOffices() {
      return this.$store.state.config.offices
    },
    configAreas() {
      return this.$store.state.config.areas
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    configClinicSelected() {
      if (this.selected && this.selected.clinicId) {
        let configClinic = this.configClinics.find(obj => obj.id == this.selected.clinicId)

        if (configClinic) {
          return configClinic
        }
      }

      return null
    },

    vehicleAreas() {
      return this.configAreas.filter(option => option.isVehicle === true)
    },

    me() { return this.$store.state.employee.me },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },

    registrationIkz() {
      return this.$store.state.registration.ikz
    },

    clinicId() {
      return this.$store.state.clinic.activeClinic ? this.$store.state.clinic.activeClinic.clinicId : -1
    },

    employees() {
      if (this.$store.state.employee.all !== null) {
        return this.$store.state.employee.all;
      } else return [];
    },

    subjects() {
      let array = []

      switch (this.filters.searchType) {
        case 0: array = this.employees
          break
        case 2: array = this.mClinics
          break
        case 4: array = this.offices
          break
        case 6: array = this.subjectsDevices
          break
        case 20: array = this.vehicles
          break
        default: array = []
          break
      }

      if (array === null) array = []

      return array.filter((option) => {

        if (option.name) {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.subjectName.toLowerCase()) >= 0
        }
        else return []
      })
    },

    subjectsDevices() {
      let medDevices = this.medicalDevices

      medDevices.forEach((medDevice) => {
        medDevice.name = `${medDevice.name} (${medDevice.shortName}), ${this.getFacility(medDevice.facility)}`
        medDevice.facilityName = this.getFacility(medDevice.facility)
      })

      return medDevices
    },

    subjectsDevicesOffice() {
      if (this.selected) {
        return this.subjectsDevices.filter((medDevice) => {
          return medDevice.officeTypes !== null && medDevice.officeTypes.includes(this.selected.type)
        })
      }

      return this.subjectsDevices
    },

    employeesFiltered() {
      let array = this.employees
      return array.filter((option) => {
        if (option.name && this.additionalName) {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.additionalName.toLowerCase()) >= 0
        }
        else return []
      })
    },

    rooms() {
      if (this.selected) {
        let array = this.allRooms

        if (this.selected && this.selected.facilityId) {

          if (!this.showAllRooms) {
            array = this.allRooms.filter(r => r.facility && r.facility.id === this.selected.facilityId)
          }

          return array.filter((option) => {

            if (option.name) {
              return option.name
                .toString()
                .toLowerCase()
                .indexOf(this.roomName.toLowerCase()) >= 0
            }
            else return []
          })
        }
        else return array
      }
      else return []
    },

    subjectLabel() {
      switch (this.filters.searchType) {
        case 0: return 'Pracownik'
        case 2: return 'Poradnia'
        case 4: return 'Gabinet'
        case 6: return 'Urządzenie'
        case 20: return 'Pojazd'
        default: return '???'
      }
    },

    popup() {
      return 'podgląd'
    },


  },

  methods: {


    getData() {
      this.$store
        .dispatch(Action.OFFICE_GET_OFFICE, this.worktimeArea.officeId)
        .then((response) => {
          this.office = response
          if (this.office) {
            this.getServices(response)
          }
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    getServices(item) {
      this.$store
        .dispatch(Action.UMX_GET_SERVICES_FOR_OFFICE, item)
        .then((response) => {
          this.contracts = response

          if (this.contracts.length > 0) {
            this.contract = this.contracts[0]
          }
        })
        .catch(error => {
          this.apiProblem(error)
        });
    },





    sortByMoment(a, b) {
      return moment(b.start).isSameOrBefore(moment(a.start)) ? 1 : -1
    },

    getEmployee(id) {
      if (this.employees) {
        let employee = this.employees.find((e) => e.id === id);
        if (employee) {
          let nameEmployee = employee.name
          return nameEmployee;
        }
      }
      return "";
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.id === id)
        return found != null ? found.name : `Brak (${id})`
      }
      else return `Brak subjednostek (${id})`
    },

    getFacilityFromRoom(room) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.internalId === room.facilityId)
        return found != null ? found : null
      }
      else return null
    },

    getFacilityCode(id) {
      if (this.facilities) {
        var found = this.facilities.find(x => x.id === id)
        return found != null ? found.internalId : -1
      }
      else return -1
    },

    getSubunit(id) {
      if (this.subunits) {
        var found = this.subunits.find(x => x.id === id)
        return found != null ? found.name : `Brak (${id})`
      }
      else return `Brak subjednostek (${id})`
    },

    getOffroomz() {
      this.$store
        .dispatch(Action.INVENTORY_GET_ALL_ROOMS)
        .then((response) => {

          response.forEach(element => {
            element.facility = this.getFacilityFromRoom(element)
            element.name = `Nr ${element.number ?? '?'}, ${this.mGetName(this.mRoomTypes, element.type)} ${element.name ?? '-'}, ${element.description}`
          });

          this.allRooms = response
        })
        .catch(error => {
          this.apiProblem(error);
        });
    },

    downloadMedicalDevices() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then((data) => {
          this.medicalDevices = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    downloaVehicles() {
      this.requestInProgress = true
      this.$store
        .dispatch(Action.MOBI2_GET_VEHICLES)
        .then((data) => {
          data.forEach(x => {
            x.name = `${x.brand} ${x.model} - ${x.registrationNumber}`
          })
          this.vehicles = data
          this.requestInProgress = false
        })
        .catch(error => {
          this.apiProblem(error);
          this.requestInProgress = false
        });
    },

    getItem(id) {
      let category = this.configOffices.find(c => c.id == id)
      if (category) return category.name
      else return `Brak informacji`
    },

    getOfficeAreas(item) {
      let category = this.configOffices.find(c => c.id == item.type)
      if (category && category.areas) return category.areas
      else return []
    },

    workerToOffice(worker, editMode) {
      if (editMode) {
        if (worker) {
          this.selectedWorktimeArea.employee = worker.id
        }
        else {
          this.selectedWorktimeArea.employee = -1
        }
      }
      else {
        if (worker) {
          this.worktimeArea.employee = worker.id
        }
        else {
          this.worktimeArea.employee = -1
        }
      }
    },

    targetChanged(item) {
      this.devicesInOffice.length = 0
      this.includedDevices.length = 0
      this.worktimeArea.officeRoomId = null
      this.worktimeArea.info = null
      this.worktimeArea.vehicleId = null
      this.worktimeArea.vehicle = -1
      this.worktimeArea.style = 0
      this.filters.vehicle = -1
      this.worktimeArea.device = -1
      this.filters.device = -1

      if (item) {
        this.selected = item

        if (item.areas && item.areas.length > 0) {
          this.worktimeArea.area = item.areas[0]
        }
        else if (this.filters.searchType === 20) {
          this.worktimeArea.vehicle = item.id
          this.filters.vehicle = item.id
        }
        else if (this.filters.searchType === 4) {
          this.worktimeArea.officeId = item.id
          this.filters.office = item.id

          if (this.clinicId > -1) {
            this.devicesInOffice = this.medicalDevices.filter(d => d.location == this.clinicId && d.office === item.id)
          }

          if (this.selected.employee) {
            this.worktimeArea.employee = this.selected.employee
            this.worktimeArea.employees = [this.selected.employee]
            this.additionalName = this.getEmployee(this.selected.employee)
          }
        }
        else if (this.filters.searchType === 6) {
          this.worktimeArea.area = -1
          this.worktimeArea.device = item.id
          this.filters.device = item.id
        }
      }
      else {
        this.subjectName = ''
        this.roomName = ''
        this.worktimeArea.officeId = null
        this.worktimeArea.officeRoomId = null
        this.filters.office = null
        this.filters.room = null
      }

      this.modalAmount = 1
      this.modalBackupAmount = 0
    },

    roomChanged(item) {
      this.includedDevices.length = 0

      if (item) {
        this.filters.room = item.id
        this.worktimeArea.officeRoomId = item.id
      }
      else {
        this.filters.room = null
        this.worktimeArea.officeRoomId = null
      }
    },

    popPosition(popX, popY) {
      return `position: fixed;top: ${popY - 40}px;left: ${popX + 5}px;  z-index: 10;`
    },

    clearDates() {

      while (this.dates.length > 0) {
        this.dates.pop()
      }

      this.clearTrigger++
      this.scheduleData = { days: [] }
    },

    updateDates() {
      this.filters.dates.length = 0

      if (this.calendarRangeMode && this.dates.length == 2) {
        while (!this.filters.dates.includes(this.mDate(this.dates[1]))) {
          if (this.filters.dates.length == 0) {
            this.filters.dates.push(this.mDate(this.dates[0]))
          } else {
            let newDate = moment(this.filters.dates[this.filters.dates.length - 1]).add(1, 'days')
            newDate = new Date(newDate)
            this.filters.dates.push(this.mDate(newDate))
          }
        }
      } else {
        this.dates.forEach(d => {
          this.filters.dates.push(this.mDate(d))
        })
      }
    },

    isIncluded(area, slot) {
      let aStart = moment(area.start)
      let aEnd = moment(area.stop)
      let slotMom = moment(slot)

      let included = slotMom.isSameOrAfter(aStart) && slotMom.isBefore(aEnd)


      return included
    },

    downloadData() {

      if (this.selected) {
        switch (this.filters.searchType) {
          case 0:
            this.filters.subjectId = this.selected.id
            this.filters.employee = this.selected.id
            break
          case 2:
            this.filters.clinicId = this.selected.id
            this.filters.clinic = this.selected.id
            break
          case 4: this.filters.office = this.selected.id
            break
          case 6: this.filters.device = this.selected.id
            break
          default:
            break
        }

        this.loading = true

        let cops = JSON.parse(JSON.stringify(this.filters))

        // if (this.filters.searchType == 4 && this.filters.room === null) {
        //   cops.searchType = 8
        // }
        if (this.filters.searchType == 4) {
          cops.searchType = 8
        }

        this.$store.dispatch(Action.S4_GET_SCHEDULE, cops)
          .then((response) => {
            this.scheduleData = { days: [] }
            this.scheduleData = response.data
            this.loading = false
            let dCount = response.data.days ? response.data.days.length : 0

            if (dCount > 0) {
              response.data.days.forEach(element => {
                let weekDay = new Date(element.date).getDay()
                if (weekDay != 0 && weekDay != 6) {
                  element.checkboxSelected = true
                } else {
                  element.checkboxSelected = false
                }
              })
            }

            this.fillSlots(dCount, response.data.days)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
            this.$emit('error')
            this.scheduleData = { days: [] }
          })
      }
    },

    countEmptySlots(worktimes, backups) {
      if (backups) {
        let empty = worktimes.filter(w => w.employee === -1 && w.area === 1)
        return empty.length
      }
      else {
        let empty = worktimes.filter(w => w.employee === -1 && w.area === 0)
        return empty.length
      }
    },

    deleteWorktime(item) {
      this.$buefy.dialog.confirm({
        message: `Czy na pewno chcesz usunąć wybraną strefę terminarza (${this.getDate(item.start)} ${this.getTime(item.start)}-${this.getTime(item.stop)})?`,
        type: 'is-danger',
        confirmText: 'Usuń',
        cancelText: 'Anuluj',
        onConfirm: () => {
          this.loading = true
          this.$store.dispatch(Action.S2_DELETE_WORKTIME, item.id)
            .then(() => {
              this.successSnackbar('Usunięto strefę')
              this.loading = false
              this.downloadData()

              //ADD IKZ - USUNIĘCIE STREFY
              this.addIkzDeleteWorktime()
            })
            .catch((error) => {
              this.loading = false
              this.apiProblem(error)
            })
        }
      })
    },

    deleteWorktimes() {
      let queue = []

      this.filters.dates.forEach(date => {

        let start = date + 'T' + this.modalStart.format('HH:mm:00')
        let stop = date + 'T' + this.modalStop.format('HH:mm:00')

        for (let index = 0; index < 1; index++) {
          queue.push({
            "id": 0,
            "start": start,
            "stop": stop,
            "subjectId": -1,
            "subject": 1,
            "version": "0",
            "employee": -1,
            "device": "",
            "office": -1,
            "officeId": this.worktimeArea.officeId,
            "officeRoomId": this.worktimeArea.officeRoomId,
            "spec": -1,
            "area": 0,
            "clinic": -1,
            "created": this.mNow(),
            "createdBy": this.me.id,
            "modified": this.mNow(),
            "modifiedBy": this.me.id
          })
        }
      })

      this.deleteSend(queue)
    },

    sendWorktimes(mode) {
      if (this.modalOccupied) {

        let message = 'Czy na pewno chcesz dodać strefę równoległą do już istniejącej?'

        if (mode == 1) {
          message = "Czy na pewno chcesz nadpisać strefę? Fragmenty istniejących stref powodujące konflikty <b>zostaną usunięte</b>."
        }

        this.$buefy.dialog.confirm({
          message: message,
          hasIcon: true,
          scroll: 'keep',
          type: mode == 1 ? 'is-warning' : 'is-info',
          cancelText: "Anuluj",
          onConfirm: () => {
            this.sendWorktimesConfirmed(mode)
          }
        });
      }
      else {
        this.sendWorktimesConfirmed(mode)
      }
    },

    sendWorktimesConfirmed(mode) {
      let amount = this.modalAmount
      // let amountBack = this.modalBackupAmount

      let employees = []
      let facility = -1

      if (this.worktimeArea.employee > -1) {
        employees = [this.worktimeArea.employee]
      }

      if (this.selected.facilityId) {
        facility = this.getFacilityCode(this.selected.facilityId)
      }

      let device = null
      let devices = []

      if (this.includedDevices.length > 0) {
        this.includedDevices.forEach(d => devices.push(d.id))
        device = devices[0]
      } else if (this.filters.searchType == 6) {
        device = this.worktimeArea.device
      }

      let clinic = this.worktimeArea.clinic

      if (this.selected.clinicId != undefined) {
        clinic = this.selected.clinicId
      }

      let queue = []

      // this.filters.dates.forEach(date => {
      this.selectedDays.forEach(date => {

        let start = ""
        let stop = ""

        if (this.isWholeDay && (this.worktimeArea.area == 99 || this.worktimeArea.area == 98)) {
          start = date + 'T06:00:00'
          stop = date + 'T20:00:00'
        } else {
          start = date + 'T' + this.modalStart.format('HH:mm:00')
          stop = date + 'T' + this.modalStop.format('HH:mm:00')
        }

        for (let index = 0; index < amount; index++) {
          queue.push({
            "id": 0,
            "start": moment(start).toDate(),
            "stop": moment(stop).toDate(),
            "subjectId": this.worktimeArea.employee,
            "info": this.worktimeArea.info,
            "subject": 0,
            "style": this.worktimeArea.style,
            "version": "0",
            "employee": this.worktimeArea.employee,
            "employees": employees,
            "facility": facility,
            //"device": this.worktimeArea.device,
            "isVisibleForPatients": this.isVisibleForPatients,
            "device": device,
            "devices": devices,
            "officeId": this.worktimeArea.officeId,
            "officeRoomId": this.worktimeArea.officeRoomId,
            "isEr": this.worktimeArea.isEr,
            "vehicle": this.worktimeArea.vehicle,
            "office": -1,
            "spec": this.worktimeArea.spec,
            "area": this.worktimeArea.area,
            "isCito": this.worktimeArea.isCito,
            "clinic": clinic,
            "created": this.mNow(),
            "createdBy": this.me.id,
            "modified": this.mNow(),
            "modifiedBy": this.me.id
          })

        }
      })

      if (this.cyclicalWorktime) {

        let arr = JSON.parse(JSON.stringify(queue))

        let unit = 'days'

        if (this.worktimePeriodUnit == 0) {
          unit = 'days'
        } else if (this.worktimePeriodUnit == 1) {
          unit = 'weeks'
        } else if (this.worktimePeriodUnit == 2) {
          unit = 'months'
        }

        if (queue.length > 1) {
          this.dangerSnackbar('Zaznaczono więcej niż jedną strefę')
        }

        if (this.cyclicalType == 0 && queue.length == 1) {
          for (let i = 0; i < this.numberOfWortimes; i++) {
            // add worktime
            queue.forEach(element => {
              let newObj = JSON.parse(JSON.stringify(element))
              newObj.start = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
              newObj.stop = moment(arr[arr.length - 1].stop).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')

              let daysToAdd = 0

              if (this.excludeWeekends) {
                let dayOfWeek = moment(newObj.start).day()
                if (dayOfWeek == 6) {
                  daysToAdd = 2
                } else if (dayOfWeek == 7) {
                  daysToAdd = 1
                }

                newObj.start = moment(newObj.start).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
                newObj.stop = moment(newObj.stop).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
              }

              newObj.start = moment(newObj.start).toDate()
              newObj.stop = moment(newObj.stop).toDate()

              arr.push(newObj)
            })
          }
        } else if (this.cyclicalType == 1 && queue.length == 1) {

          //

          let nextWorktimeStart = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
          let worktimeEnd = moment(this.worktimePeriodEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS')

          while (nextWorktimeStart < worktimeEnd) {

            queue.forEach(element => {
              let newObj = JSON.parse(JSON.stringify(element))
              newObj.start = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
              newObj.stop = moment(arr[arr.length - 1].stop).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')

              let daysToAdd = 0

              if (this.excludeWeekends) {
                let dayOfWeek = moment(newObj.start).day()
                if (dayOfWeek == 6) {
                  daysToAdd = 2
                } else if (dayOfWeek == 7) {
                  daysToAdd = 1
                }

                newObj.start = moment(newObj.start).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
                newObj.stop = moment(newObj.stop).add(parseFloat(daysToAdd), 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
              }

              newObj.start = moment(newObj.start).toDate()
              newObj.stop = moment(newObj.stop).toDate()

              arr.push(newObj)

              nextWorktimeStart = moment(arr[arr.length - 1].start).add(parseFloat(this.worktimePeriodNumber), unit).format('YYYY-MM-DDTHH:mm:ss.SSS')
            })
          }
        }

        let obj = {
          "forceMode": 0,
          "worktimes": arr
        }

        this.sendedWorktimes = JSON.parse(JSON.stringify(arr))

        this.loading = true
        this.$store.dispatch(Action.S3_WORKTIMES_CYCLICAL, obj)
          .then((response) => {
            if (response && response.data) {
              if (response.data.success) {
                this.successSnackbar('Pomyślnie dodano')
                this.$emit('updated')
                //ADD IKZ - UTWORZENIE STREFY
                this.addIkzAddWorktime()
              } else {
                this.cyclicalWorktimeSaveResponse = response.data
                this.openCyclicalWorktimeModal()
              }
            }
            this.loading = false
            //this.downloadData()
            this.isModalActive = false
            this.isVisibleForPatients = false
            this.cyclicalWorktime = false
            this.isWholeDay = false
            this.worktimePeriodEndDate = new Date()
            this.worktimePeriodNumber = 1
            this.numberOfWortimes = 1
            this.worktimePeriodUnit = 0
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })


      } else {
        this.multiSend(queue, mode)
        this.specialArea = false
      }


    },

    multiSend(arr, mode) {
      this.loading = true

      var tmp = {
        mode: mode,
        items: arr
      }

      // cool cool cool
      this.$store.dispatch(Action.SCHEDULE4_ADD_AREA_EXPANDED, tmp)
        .then(() => {
          this.successSnackbar('Pomyślnie dodano')
          this.loading = false
          //this.downloadData()
          this.isModalActive = false
          this.isWholeDay = false

          //ADD IKZ - UTWORZENIE STREFY
          this.addIkzAddWorktime()

          this.$emit('updated')

        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })

    },

    deleteSend(arr) {
      this.loading = true
      this.$store.dispatch(Action.S3_DELETE_WORKTIMES, arr)
        .then(() => {
          this.successSnackbar('Usunięto strefy')
          this.loading = false
          //this.downloadData()
          this.isModalActive = false
          this.$emit('updated')
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    chainSend(arr) {
      if (arr.length > 0) {
        this.loading = true
        this.$store.dispatch(Action.S2_SEND_WORKTIME, arr[0])
          .then(() => {
            arr.splice(0, 1)
            this.successSnackbar('Zapisano na serwerze' + arr.length)
            this.loading = false
            this.chainSend(arr)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        this.isModalActive = false
        this.$emit('updated')
      }
    },

    clearSelected() {
      this.modalOccupied = false
      this.$emit('clear')
    },

    addFive(time) {
      return moment(time).add(5, 'minutes').format('HH:mm')
    },

    mousedown(item) {

      if (this.dragging) {
        this.markTwo = item
      }
      else {
        this.markOne = item
      }

      if (this.markOne != null && this.markTwo != null) {

        let occu = false

        for (let index = this.markOne; index <= this.markTwo; index++) {
          this.slots[index].selected = true

          if (this.slots[index].occupied) {
            occu = true
          }
        }

        this.modalOccupied = occu

        // this.isModalActive = true
        this.checkSelectedDays()

        this.modalStart = moment(this.slots[this.markOne].time)
        this.modalStop = moment(this.slots[this.markTwo].time).add(5, 'minutes')

        this.markOne = null
        this.markTwo = null
      }

      this.dragging = !this.dragging
    },

    getCellStyle(slotIndex, dayIndex) {
      return this.styles[slotIndex][dayIndex]
      // let ref = 'slot' + (index * 100 + index2)

      // if (this.$refs[ref]) {
      // }

      // let id = index * 100 + index2
      // let sty = this.styles.find(s => s.id == id)

    },

    mouseover(item, hover) {
      // if (this.slots[item].hovered != hover) {
      //   this.slots[item].hovered = hover
      // }

      if (this.markOne != null) {
        let tmpMark = item

        for (let index = this.markOne; index <= tmpMark; index++) {
          this.slots[index].selected = !this.slots[index].selected
        }
      }

      if (hover) {
        this.popX = event.clientX
        this.popY = event.clientY
      }

      this.tmp = item
    },

    clearPop() {
      this.popX = null
      this.popY = null
    },

    getWorker(id) {
      if (this.employees) {
        let emp = this.employees.find(e => e.id === id)

        if (emp) {
          return emp.lastName + ' ' + emp.firstName
        }
        else return 'brak informacji'
      }
    },


    getWorkAreaName(id) {
      let category = this.configAreas.find(c => c.id == id)
      if (category) return category.name
      else return `??? [${id}]`
    },

    fillSlots(days, dayData) {
      this.slots.length = 0
      this.styles.length = 0

      let date = moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
      let end = moment().set({ hour: 19, minute: 59, second: 0, millisecond: 0 })

      while (date.isBefore(end)) {
        let slot = {
          time: date,
          selected: false,
          hovered: false,
          timeString: 'T' + moment(date).format('HH:mm:00')
        }

        let slotsStyles = []

        for (let index = 0; index < days; index++) {

          if (dayData) {
            const item = dayData[index]



            let base = 'min-height: 4px; height: 4px;border-top: 1px solid lightgrey;border-left: 1px solid lightgrey;'
            let width = 20 / days

            base = base + `width:${width}rem;`

            let slotTime = moment(item.date).format('YYYY-MM-DD') + slot.timeString
            let included = item.worktimes.find(s => this.isIncluded(s, slotTime))

            if (included && item.checkboxSelected) {




              let colot = 'background-color: rgba(150, 99, 71, 0.2);'

              switch (included.area) {
                case 0:
                case 4:
                case 100000:
                case 100100:
                case 100200:
                case 100300:
                case 100400:
                case 100500:
                case 100600:
                case 100700:
                  colot = 'background-color: rgba(255, 99, 71, 0.3);'
                  break
                case 1:
                case 5:
                case 100402:
                case 100502:
                  colot = 'background-color: rgba(99, 255, 71, 0.3);'
                  break
                case 2:
                case 6:
                case 100403:
                case 100503:
                  colot = 'background-color: rgba(71, 99, 255, 0.2);'
                  break
                case 3:
                case 7:
                case 100404:
                  colot = 'background-color: rgba(255, 150, 199, 0.3);'
                  break
                case 99: colot = 'background-color: rgba(255, 237, 71, 0.5);'
                  break
              }
              slot.occupied = true
              base = base + colot
            } else if (included && !item.checkboxSelected) {
              let colot = 'background-color: rgba(191, 191, 191, 1);'
              slot.occupied = true
              base = base + colot
            } else if (!item.checkboxSelected) {
              let colot = 'background-color: rgba(191, 191, 191, 1);'
              base = base + colot
            }


            if (included) {
              let begining = moment(included.start)
              let slotBegining = moment(slotTime)

              if (slotBegining.isSame(begining)) {
                base += 'border-top: 1px solid black !important;'
              }
            }

            slotsStyles.push(base)
          } else {
            slotsStyles.push(null)
          }
        }

        this.slots.push(slot)
        this.styles.push(slotsStyles)

        date = moment(date).add(5, 'minutes')
      }
    },

    checkSelectedDays() {
      this.selectedDays.length = 0
      this.scheduleData.days.forEach(day => {
        if (day.checkboxSelected) {
          this.selectedDays.push(moment(day.date).format('YYYY-MM-DD'))
        }
      })
      this.$nextTick(() => {
        this.isModalActive = true
      })
    },

    openEditModal(item) {


      this.selectedWorktimeArea = item

      if (item.area == 98) {
        this.specialArea = true
      }

      this.additionalName = this.getEmployee(item.employee)

      this.selectedDates.length = 0

      this.selectedDates.push(new Date(item.start))
      this.selectedDates.push(new Date(item.stop))

      this.includedDevices.length = 0;

      if (item.devices && item.devices.length > 0) {
        if (this.subjectsDevicesOffice != null && this.subjectsDevicesOffice.length > 0) {
          item.devices.forEach(dev => {
            let found = this.subjectsDevicesOffice.find(d => d.id == dev)
            if (found != null && !this.includedDevices.some(incD => incD.id == found.id)) {
              this.includedDevices.push(found)
            }
          });
        } else {
          this.dangerSnackbar('Brak załadowanych urządzeń')
        }
      }

      //
      this.isEditModalActive = true
    },

    saveEditedWortimekArea() {
      //
    },

    getDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },

    getTime(date) {
      return moment(date).format('HH:mm')
    },

    modifyWorktime(worktime) {
      this.loading = true

      worktime.start = moment(this.selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss')
      worktime.stop = moment(this.selectedDates[1]).format('YYYY-MM-DDTHH:mm:ss')

      if (worktime.employee > -1) {
        worktime.employees = [worktime.employee]
      } else {
        worktime.employees = []
      }

      let device = null
      let devices = []

      if (this.includedDevices.length > 0) {
        this.includedDevices.forEach(d => devices.push(d.id))
        device = devices[0]
      }

      worktime.devices = devices
      worktime.device = device

      this.$store.dispatch(Action.S3_MODIFY_WORKTIME, worktime)
        .then(() => {
          this.successSnackbar('Zapisano zmiany')
          this.loading = false
          this.isEditModalActive = false

          this.$emit('updated')

          //ADD IKZ - EDYCJA STREFY
          this.addIkzEditWorktime()
        })
        .catch((error) => {
          this.loading = false
          this.apiProblem(error)
        })
    },

    isWorktime(day) {
      let worktime = day.blocks.find(bl => bl.isWorktime)

      if (worktime) {
        return true
      } else return false
    },

    moveDayWorktimes() {

      let worktimes = this.selectedAreas.worktimes

      worktimes.forEach(element => {
        element.start = moment(new Date(element.start)).add(this.minutes, 'minutes').format('YYYY-MM-DDTHH:mm:00')
        element.stop = moment(new Date(element.stop)).add(this.minutes, 'minutes').format('YYYY-MM-DDTHH:mm:00')
      })

      this.chainSend2(worktimes)
    },

    chainSend2(arr) {
      if (arr.length > 0) {
        this.loading = true
        this.$store.dispatch(Action.S3_MODIFY_WORKTIME, arr[0])
          .then(() => {
            arr.splice(0, 1)
            this.successSnackbar('Zapisano na serwerze' + arr.length)
            this.loading = false
            this.chainSend2(arr)
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        this.isEditMultiModalActive = false
        this.$emit('updated')
      }
    },

    openCyclicalWorktimeModal() {
      this.isCyclicalWorktimesActive = true
    },

    getFormattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },

    saveCyclicalWorktimesWithConflicts(method) {

      if (method == 1) {

        var tmp = {
          mode: 1,
          items: this.sendedWorktimes
        }

        this.loading = true
        this.$store.dispatch(Action.SCHEDULE4_ADD_AREA_EXPANDED, tmp)
          .then(() => {
            this.loading = false
            this.successSnackbar('Pomyślnie dodano')

            this.isCyclicalWorktimesActive = false
            this.sendedWorktimes = null
            this.cyclicalWorktimeSaveResponse = null

            //ADD IKZ - UTWORZENIE STREFY
            this.addIkzAddWorktime()

            this.$emit('updated')

          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
      else {
        let obj = {
          "forceMode": method,
          "worktimes": this.sendedWorktimes
        }

        this.loading = true
        this.$store.dispatch(Action.S3_WORKTIMES_CYCLICAL, obj)
          .then((response) => {
            if (response && response.data) {
              if (response.data.success) {
                this.successSnackbar('Pomyślnie dodano')
                this.isCyclicalWorktimesActive = false
                this.sendedWorktimes = null
                this.cyclicalWorktimeSaveResponse = null

                //ADD IKZ - UTWORZENIE STREFY
                this.addIkzAddWorktime()

              } else {
                this.cyclicalWorktimeSaveResponse = response.data
                this.openCyclicalWorktimeModal()
              }
            }
            this.loading = false
            this.downloadData()
            this.isModalActive = false
            this.isVisibleForPatients = false
            this.cyclicalWorktime = false
          })
          .catch((error) => {
            this.loading = false
            this.apiProblem(error)
          })
      }
    },

    getMedicalDevice(id) {
      if (id) {
        let medDevice = this.subjectsDevices.find(option => option.id == id)

        if (medDevice) {
          return medDevice.name
        } else return `??? [${id}]`
      } else return 'Brak danych'
    },

    addIkzAddWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 589)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzEditWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 590)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzDeleteWorktime() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 591)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": 0,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hovered:hover {
  background: skyblue;
}
.selected {
  background: green;
}
.marked {
  background: green;
}
.topped {
  border-top: 2px solid red;
}
.vam {
  vertical-align: middle;
}
</style>
